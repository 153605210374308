<template>
	<div class="FM_page-accountOther">
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-6 col-lx-6 offset-lg-3 offset-xl-3">
					<ValidationObserver v-slot="{ handleSubmit }">
						<form class="FM_form-send" autocomplete="off" @submit.prevent="handleSubmit(submit)">
							<h3 class="FM_title-default">{{ $t('__contactUs') }}</h3>
							<p class="text-light text-center">{{ $t('__msgSpeed') }}</p>
							<div class="alert alert-danger" role="alert" v-show="formStatus.success === 0">
								{{ formStatus.errorMsg }}
							</div>
							<div class="form-group">
								<ValidationProvider rules="required" :name="$t('__content')" v-slot="{ errors }">
									<textarea type="text" rows="5" v-model="form.feedback" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" style="padding: 30px"></textarea>
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<div class="FM_btn-group">
								<button type="submit" class="btn btn-block FM_btn btn-success font-weight-bold" :disabled="formStatus.progress" data-toggle="modal">
									<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="formStatus.spinner"></span>
									{{ $t('__submit') }}
								</button>
							</div>
						</form>
					</ValidationObserver>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { setStorage, getStorage, removeAllStorage } from "@/utils/storage.js"
import { extend } from 'vee-validate'
import { required, min } from 'vee-validate/dist/rules'
import CN from 'vee-validate/dist/locale/zh_CN.json'
import { localize } from 'vee-validate'
import Footer from '@/components/share/Footer.vue'

export default {
	name: "Support",
	data() {
		return{
			form: {
				feedback: ''
			},
			formStatus: {
				spinner: false,
				progress: false,
				success: null, // 0:fail / 1:success / null: not registered
				errorMsg: ''
			},
		}
	},
	created() {
		if (getStorage('lang') === 'cn') {
			localize('zh_CN', CN);
		}
		extend('required', required);
		extend('min', min);
	},
	methods: {
		submit() {
			this.$Progress.start();
			this.formStatus.spinner = true;
			this.formStatus.progress = true;
			let _data = {
				message: this.form.feedback
			};

			Ajax(
				API.support.method,
				API.support.url,
				_data
			).then(result => {
				this.formStatus.spinner = false;
				this.formStatus.progress = false;

				if(result.success !== 1) {
					this.formStatus.success = result.success;
					this.formStatus.errorMsg = result.error_msg;
					this.$Progress.finish();
					return ;
				}

				this.$Progress.finish();
				this.$router.push('/');
			});
		}
	},
	components: {
		Footer
	}
}
</script>

<style scoped>

</style>